import React from 'react'
import PuffLoader from "react-spinners/PuffLoader";
const PuffComponent = ({
    Value1,
    Value2,
    Value3,
}) => {
    return (
        <>
            <PuffLoader
                size={Value1}
                loading={Value2}
                speedMultiplier={Value3}
            />
        </>
    )
}

export default PuffComponent
