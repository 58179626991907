import React from 'react';
import { PersonalPurchase } from '../../data/DashboardWidgetsIcon';
import { UsersIcon } from '../../data/DashboardWidgetsIcon';
import { CertificateIcon } from '../../data/DashboardWidgetsIcon';
import { EnvelopeCheckIcon } from '../../data/DashboardWidgetsIcon';
import { RevenueIcon } from '../../data/DashboardWidgetsIcon';
import { CustomIcon } from '../../data/DashboardWidgetsIcon';
import { ShopIcon } from '../../data/DashboardWidgetsIcon';
import { CoinIcon } from '../../data/DashboardWidgetsIcon';
import { BarChartFillIcon } from '../../data/DashboardWidgetsIcon';
const DashboardWidgets = () => {
    const Widgets = [
        // {
        //     id: 1,
        //     Title: 'خرید شخصی این ماه',
        //     Count: 0,
        //     Unit: 'ریال',
        //     backgroundColor: 'bg-[rgba(0,59,155,1)]',
        //     img: <PersonalPurchase />
        // },
        {
            id: 2,
            Title: 'تعداد زیرمجموعه',
            Count: 0,
            Unit: 'جایگاه',
            backgroundColor: 'bg-gradient-to-r from-[rgba(21,67,96)] to-[rgba(52,152,219)]',
            img: <UsersIcon />
        },
        {
            id: 3,
            Title: 'رتبه سازمانی',
            Count: '',
            Unit: 'نوآموز',
            backgroundColor: 'bg-gradient-to-r from-[rgba(21,67,96)] to-[rgba(52,152,219)]',
            img: <CertificateIcon />
        },
        // {
        //     id: 4,
        //     Title: 'پیام های دریافت شده',
        //     Count: '',
        //     Unit: '',
        //     backgroundColor: 'bg-[rgba(179,-17,75,1)]',
        //     img: <EnvelopeCheckIcon />
        // },
        {
            id: 5,
            Title: 'مجموع پورسانت دریافتی',
            Count: 0,
            Unit: 'ریال',
            backgroundColor: 'bg-gradient-to-r from-[rgba(21,67,96)] to-[rgba(52,152,219)]',
            img: <RevenueIcon />
        },
        {
            id: 6,
            Title: 'امتیاز خرید شخصی این ماه',
            Count: 0,
            Unit: 'ریال',
            backgroundColor: 'bg-gradient-to-r from-[rgba(21,67,96)] to-[rgba(52,152,219)]',
            img: <PersonalPurchase />
        },
        // {
        //     id: 7,
        //     Title: 'دنیای ویستا',
        //     Count: '',
        //     Unit: '',
        //     backgroundColor: 'bg-[rgba(208,-44,116,1)]',
        //     img: <CustomIcon />
        // },
        // {
        //     id: 8,
        //     Title: 'فروشگاه من',
        //     Count: '',
        //     Unit: '',
        //     backgroundColor: 'bg-[#6078ea]',
        //     img: <ShopIcon />
        // },
        // {
        //     id: 9,
        //     Title: 'کیف پول',
        //     Count: '',
        //     Unit: '',
        //     backgroundColor: 'bg-[rgba(208,-44,116,1)]',
        //     img: <CoinIcon />
        // },
        // {
        //     id: 10,
        //     Title: 'سیب یار',
        //     Count: '',
        //     Unit: '',
        //     backgroundColor: 'bg-[#6078ea]',
        //     img: <BarChartFillIcon />
        // },

    ]
    return (
        <div className='w-full flex justify-center'>
            <div className='container'>
                <div className='w-ful px-5 py-10 sm:pb-10 md:pb-10 lg:pb-10 mt-0'>
                    <div className='w-full grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-4'>
                        {
                            Widgets.map((item, index) => (
                                <div key={index} className={`w-full h-[120px] rounded-2xl ${item.backgroundColor}`}>
                                    <div className='w-full h-full flex'>
                                        <div className='h-full flex items-center px-10'>
                                            <div className='w-[60px] h-[60px] sm:w-[30px] sm:h-[30px] 2xl:h-[50px] 2xl:w-[50px] p-[5px] 2xl:p-[8px] flex justify-center items-center rounded-full bg-[rgba(255,255,255,0.9)]'>
                                                {item.img}
                                            </div>
                                        </div>
                                        <div className='h-full w-full flex justify-end items-center px-5'>
                                            <div className='text-white'>
                                                <div className='text-[14px] sm:text-sm lg:text-lg xl:text-[14px] 2xl:text-[18px]'>{item.Title}</div>
                                                <div className='flex justify-end mt-5'>
                                                    <span className='text-[18px]'>{item.Unit}</span>
                                                    <span className='mx-3'>{item.Count}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DashboardWidgets
