import {
    DashboardIcon,
    Linear,
    SalesNetworkIcon,
    CommissionIcon,
    MyShopIcon,
    TeamMembersIcon,
    DeliveryIcon,
    SupportIcon,
    ProfileIcon,
    LogoutIcon,
    ProductTransfer,
    ProductTransfer2,
} from '../data/Icon.js';

export const Lists = [
    {
        id: 1,
        name: 'پیشخوان',
        icon: <DashboardIcon />,
        options: [],
        href: '/'
    },
    {
        id: 2,
        name: 'شبکه فروش',
        icon: <SalesNetworkIcon />,
        options: [
            {
                name: 'شبکه فروش درختی',
                href: '/shematic/tree',
                icon: <SalesNetworkIcon />
            },
            {
                name: 'شبکه فروش خطی',
                href: '/shematic/linear',
                icon: <Linear />
            },
        ],
        href: '#'
    },
    {
        id: 3,
        name: 'پورسانت و فروش',
        icon: <CommissionIcon />,
        options: [
            {
                name: 'پورسانت دریافتی',
                href: '/commission/received',
                icon: <CommissionIcon />

            },
            {
                name: 'گزارش فروش گذشته',
                href: '#',
                icon: <CommissionIcon />

            }
        ],
        href: '#'
    },
    {
        id: 4,
        name: 'افراد تیم',
        icon: <TeamMembersIcon />,
        options: [
            {
                name: 'فعالترین افراد تیم',
                href: '#',
                icon: <TeamMembersIcon />
            },
            {
                name: 'افراد خرید نزده',
                href: '#',
                icon: <TeamMembersIcon />
            },
        ],
        href: '#'
    },
    {
        id: 5,
        name: 'اموزش',
        icon: <TeamMembersIcon />,
        options: [
            {
                name: 'دنیای ویستا',
                href: '#',
                icon: ''
            },
            {
                name: 'سیب یار',
                href: '#',
                icon: ''
            },
        ],
        href: '#'
    },
    {
        id: 6,
        name: 'حواله تحویل کالا و ادرس ها',
        icon: <DeliveryIcon />,
        options: [
            {
                name: 'حواله تحویل کالا',
                href: '#',
                icon: <ProductTransfer />
            },
            {
                name: 'آدرس های ارسال سفارش',
                href: '#',
                icon: <ProductTransfer2 />
            },
        ],
        href: '#'
    },
    {
        id: 7,
        name: 'فروشگاه من',
        icon: <MyShopIcon />,
        options: [],
        href: '#'
    },
    {
        id: 8,
        name: 'تیکت پشتیبانی',
        icon: <SupportIcon />,
        options: [],
        href: '#'
    },
    {
        id: 9,
        name: 'مشخصات کاربری',
        icon: <ProfileIcon />,
        options: [],
        href: '#'
    },
    {
        id: 10,
        name: 'خروج از حساب کاربری',
        icon: <LogoutIcon />,
        options: [],
        href: '#'
    },
];
