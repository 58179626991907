import React, { useState } from 'react'
import { CommissionReceivedList } from '../../data/CommissionReceived';
import Calendar from '../../assets/icons/calendar.png';
import Cancel from '../../assets/icons/cancel.png';
import Success from '../../assets/icons/success.png';
const CommissionReceived = (props) => {
    const {
        width,
        Loading,
    } = props
    const [modalStatus, setModalStatus] = useState(false)
    const [ListsInfo, setListsInfo] = useState([])
    const CreatingListFunction = (object) => {
        setListsInfo(object.Detail)
        setModalStatus(true)
    }
    return (
        <div className='w-full mt-16 lg:mt-0 lg:w-[calc(100vw-350px)] h-screen overflow-x-hidden card relative'>
            <div className='xl:container'>
                {
                    modalStatus && <div className='absolute top-0 left-0 w-full h-full flex  justify-center items-center z-40 bg-[rgba(0,0,0,0.5)]'>
                        <div className='w-[80%] h-[80%] rounded-3xl bg-white px-5 overflow-y-scroll card'>
                            <div className='w-full h-[60px] flex items-center justify-between border-b-[2px] border-[rgba(0,0,0,0.5)]'>
                                <div className='flex-1 flex justify-center sm:text-sm  md:text-md xl:text-lg 2xl:text-xl'>وضعیت</div>
                                <div className='flex-1 flex justify-center sm:text-sm  md:text-md xl:text-lg 2xl:text-xl'>تا</div>
                                <div className='flex-1 flex justify-center sm:text-sm  md:text-md xl:text-lg 2xl:text-xl'>از</div>
                                <div className='flex-1 flex justify-center sm:text-sm  md:text-md xl:text-lg 2xl:text-xl'>پلن</div>
                                <div className='flex-1 flex justify-center sm:text-sm  md:text-md xl:text-lg 2xl:text-xl'>واحد</div>
                                <div className='flex-1 flex justify-center sm:text-sm  md:text-md xl:text-lg 2xl:text-xl'>مبلغ</div>
                            </div>
                            {
                                ListsInfo.map((ite, idx) => (
                                    <div key={idx} className='w-full CommissionListBoxShadow h-[50px] mt-5 rounded-[10px]'>
                                        <div className='w-full h-full flex justify-between items-center'>
                                            <div className='flex-1 flex justify-center sm:text-sm  md:text-md xl:text-lg 2xl:text-xl'>{ite.Status ? <img src={Success} className='w-[30px]' alt='Success' /> : <img src={Cancel} className='w-[30px]' alt='Cancel' />}</div>
                                            <div className='flex-1 flex justify-center sm:text-sm  md:text-md xl:text-lg 2xl:text-xl'>{ite.End}</div>
                                            <div className='flex-1 flex justify-center sm:text-sm  md:text-md xl:text-lg 2xl:text-xl'>{ite.From}</div>
                                            <div className='flex-1 flex justify-center sm:text-sm  md:text-md xl:text-lg 2xl:text-xl'>{ite.Plan}</div>
                                            <div className='flex-1 flex justify-center sm:text-sm  md:text-md xl:text-lg 2xl:text-xl'>{ite.Unit}</div>
                                            <div className='flex-1 flex justify-center sm:text-sm  md:text-md xl:text-lg 2xl:text-xl'>{ite.Price}</div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                }
                <div className='absolute top-0 left-0 w-full h-full z-10 flex items-center justify-center'>
                    <div className='w-full h-full p-10 flex items-center justify-center'>
                        <div className='w-full h-full mb-10'>
                            <ul className='w-full grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-4 gap-3' style={{ 'direction': 'rtl' }}>
                                {
                                    CommissionReceivedList.map((item, index) => (
                                        <li onClick={() => CreatingListFunction(item)} key={index} className='w-full h-[60px] cursor-pointer flex rounded-[100px] bg-[#107bc2]'>
                                            <div className='h-full flex items-center px-1'>
                                                <img src={Calendar} className='w-[60px] p-1 rounded-full bg-[#236f88] border-[3px] border-[rgba(255,255,255,1)]' />
                                            </div>
                                            <div className='w-full h-full flex items-center justify-start rounded-l-[100px]'>
                                                <span className='text-[rgba(255,255,255,1)] sm:textt-sm md:text-md lg:text-base  xl:text-xl 2xl:text-md mx-4'>{item.Month}</span>
                                                <span className='text-[rgba(255,255,255,1)] sm:textt-sm md:text-md lg:text-base  xl:text-xl 2xl:text-md mx-2'>{item.Year}</span>
                                            </div>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CommissionReceived
