import React, { useEffect, useState } from 'react';
import PuffComponent from '../PuffComponent/PuffComponent';

const MenuComponent = ({
    Lists,
    C1,
    C2,
    C3,
    C4,
    C5,
    C6,
    SubMenuLoaderFunc,
}) => {
    const [DropDown, setDropDown] = useState(1)
    const pathname = window.location.pathname
    useEffect(() => {
        switch (pathname) {
            case '/':
                setDropDown(1);
                break;
            case '/shematic/tree':
                setDropDown(2);
                break
            case '/shematic/linear':
                setDropDown(2);
                break
            case '/commission/received':
                setDropDown(3);
                break
            default:
                setDropDown(1);
                break
        }
    }, [pathname])
    return (
        <div className={C1}>
            <ul className={C2}>
                {Lists.map((item, index) => (
                    <li onClick={() => setDropDown(item.id)} onMouseLeave={() => () => SubMenuLoaderFunc([], item.id)} key={index} className={`w-full mt-[2px] py-3 ${item.id == DropDown ? 'bg-[#176B87] text-[#ffffff]' : 'bg-[rgba(255,255,255,0.9)]'} hover:bg-[#176B87] hover:text-[#ffffff]`}>
                        <a href={item.href} className='w-full'>
                            <div className={`w-full relative flex cursor-pointer justify-end items-center`}>
                                <span>
                                    {item.name}
                                </span>
                                <span className={`mx-5 ${index == 0 ? C4 : C5}`}>
                                    {item.icon}
                                </span>
                                {
                                    item.options.length > 0 &&
                                    <div className={C6}>
                                        <PuffComponent
                                            Value1={20}
                                            Value2={true}
                                            Value3={0.9}
                                        />
                                    </div>
                                }
                            </div>
                        </a>
                        <div className='w-full'>
                            {
                                item.options.length > 0 &&
                                <ul className={`w-full`}>
                                    {
                                        item.options.map((ite, idx) => (
                                            <li key={idx} className='w-full'>
                                                <a href={ite.href} className={`w-full px-10 mt-5  items-center cursor-pointer justify-end ${item.id == DropDown ? 'flex' : 'hidden'}`}>
                                                    <span className='px-5'>{ite.name}</span>
                                                    <span className={`${idx == 2 ? '-translate-x-[5px]' : ''}`}>{ite.icon}</span>
                                                </a>
                                            </li>
                                        ))
                                    }
                                </ul>
                            }
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default MenuComponent;
